<template>
    <div>
        <!--    MODALS ================================================= = -->
        <modal name="my-modal" height="auto" :scrollable="true">
            <div class="modal-content">
                <div class="modal-card">
                    <div class="card-header">
                        <h4 class="card-header-title">Tambah Formulir PPDB</h4>
                        <button type="button" class="close" @click="hide_modal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form v-on:submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-body" style="max-height: none;">
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <div class="col-4">
                                        <label>Kode Formulir<span class="text-primary">*</span></label>
                                        <input type="text" :readonly="readonlyDisableKode" v-model="form.kode_formulir" maxlength="3" minlength="3" class="form-control" onkeyup="this.value = this.value.toUpperCase()">
                                    </div>
                                    <div class="col-4">
                                        <label>Unit <span class="text-primary">*</span></label>
                                        <select v-model="form.unit_id" class="form-control" @change="addsubunit()">
                                            <option v-for="(unit, index) in dataUnit" :key="index" :value="unit.id">
                                                {{ unit.title }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-4">
                                        <label>Sub Unit</label>
                                        <select v-if="dataSubUnit.length == 0" v-model="form.sub_unit_id" class="form-control">
                                            <option value=""></option>
                                        </select>
                                        <select v-else v-model="form.sub_unit_id" class="form-control">
                                            <option value=""></option>
                                            <option v-for="(sub_unit, index) in dataSubUnit" :key="index" :value="sub_unit  .id">
                                                {{ sub_unit.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="row align-items-center">
                                    <div class="col-5">
                                        <label>Nama Formulir <span class="text-primary">*</span></label>
                                        <input type="text" v-model="form.nama_formulir" class="form-control">
                                    </div>
                                    <div class="col-7">
                                        <label>keterangan<span class="text-primary">*</span></label>
                                        <input type="text" v-model="form.keterangan" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group" v-show="showHarga">
                                <label>Biaya Formulir <span class="text-primary">*</span></label>
                                <div class="row align-items-center">
                                    <div class="col-6">
                                        <select class="form-control custom-select" v-model="form.statusPemb" @change="pilihanstatus">
                                            <option value="">Pilih</option>
                                            <option value="Berbayar">Berbayar</option>
                                            <option value="Gratis">Gratis</option>
                                        </select>
                                    </div>
                                    <div class="col-6">
                                        <input type="number" v-show="showSH" :readonly="readonlyDisable" v-model="form.biaya_formulir" class="form-control" placeholder="Nominal" />
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>Kuota Pendaftar<span class="text-primary">*</span></label>
                                <input type="number" v-model="form.kuota_pendaftar" class="form-control">
                            </div>
                            <div class="form-group mb-4 border-bottom pb-4 w-100">
                                <label>Upload Cover</label>
                                <div class="custom-file">
                                    <input type="file" :name="'file_attachment'" style="cursor:pointer !important" class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" @change="previewFiles($event)" accept="image/x-png,image/jpg,image/jpeg">
                                    <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                </div>

                                <div class="d-none mt-3" :id="'file-uploaded-section'">
                                    <div class="mb-3">
                                        <div class="file-uploaded-area" :id="'file-uploaded-area'"></div>
                                    </div>
                                </div>

                                <div class="mt-3" :id="'file-uploaded-section-before'">
                                    <div class="mb-3">
                                        <div :id="'file-uploaded-area-temp'" class="file-uploaded-area" v-bind:style="{ 'background-image': 'url(https://scolacdn.com/cms/default/default_banner.jpg)' }"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer border-top-0">
                            <ul class="pagination mb-0"></ul>
                            <progress class="d-none" :id="'upload-bar'" max="100" :value.prop="uploadPercentage" style="width:100%"></progress>
                            <div class="d-flex justify-content-end">
                                <button type="button" class="btn btn-text text-muted" @click="hide_modal()">Batal & Tutup</button>
                                <button disabled :id="'progress-button'" type="button" class="btn btn-primary d-none">Uploading {{uploadPercentage}}%...</button>
                                <button :id="'upload-button'" type="submit" class="btn btn-primary">Simpan & Buat Formulir</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </modal>
        <!-- MAIN CONTENT ================================================= = -->
        <div class="row justify-content-center">
            <div class="col-12 col-lg-12 col-xl-12">
                <!-- HEADER -->
                <div class="d-block pt-3 mb-4">
                    <div class="row align-items-center">
                        <div class="col">
                            <h1 class="header-title">
                                Master Formulir PPDB
                                <small class="form-text text-muted mt-2">
                                    Menu ini digunakan untuk mengelola macam-macam formulir beserta keterangan umum yang akan digunakan pada menu gelombang PPDB.
                                </small>
                            </h1>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-primary ml-2" @click="show_modal()">
                                <div class="d-flex align-items-center">
                                    <i class="fe fe-plus mr-2"></i>Tambah Formulir
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
                <!-- CONTENT -->
                <div class="tab-content">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari unit...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-sm table-nowrap card-table">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th class="w-100">Nama Formulir</th>
                                            <th>Biaya Formulir</th>
                                            <th>Quota</th>
                                            <th>
                                                <a href="#" class="text-muted sort" data-sort="orders-status">
                                                    Action
                                                </a>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="fetching">
                                        <tr>
                                            <td colspan="5">
                                                <div class="alert alert-primary" role="alert">
                                                    Sedang mengambil data..
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!fetching">
                                        <tr v-for="(formulir, index) in dataFormulir" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>
                                                <div class="d-block">
                                                    <h2 class="mb-3 name">
                                                        <a>{{ formulir.nama_formulir }}</a>
                                                    </h2>
                                                    <div class="d-flex align-items-center flex-wrap">
                                                        <span class="badge badge-info mr-3">Kode : {{ formulir.kode_formulir }}</span>
                                                        <span v-if="formulir.unit_title" class="badge badge-success mr-3">Unit : {{ formulir.unit_title }}</span>
                                                        <span v-if="formulir.sub_unit_title" class="badge badge-warning mr-3">Sub Unit : {{ formulir.sub_unit_title }}</span>
                                                        <span v-if="formulir.keterangan" class="badge badge-light mr-3">Ket : {{ formulir.keterangan }}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>Rp. {{ formatNumber(formulir.biaya_formulir) }}</td>
                                            <td>{{ formulir.kuota_pendaftar }} Orang</td>
                                            <td class="text-center">
                                                <button @click.prevent="editForm(formulir.id) " class="btn btn-info btn-sm mr-2"><i class="fe fe-edit mr-2"></i>Edit</button>
                                                <button @click.prevent="postDelete(formulir.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2 mr-2"></i>Delete</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        data() {
            return {
                baseUrl: this.$apiconfig,
                dataFormulir: [],
                file_attachment: [],
                dataUnit: [],
                dataSubUnit: [],
                uploadPercentage: 0,
                fetching: true,
                form: {
                    kode_formulir: '',
                    nama_formulir: '',
                    title: '',
                    unit_id: '',
                    sub_unit_id: '',
                    keterangan: '',
                    biaya_formulir: '',
                    kuota_pendaftar: '',
                    status: '',
                    statusPemb: '',
                    cover: ''
                },
                showHarga: true,
                showSH: false,
                readonlyDisable: true,
                readonlyDisableKode: false,
                pagination: {
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: ''
            }
        },
        created() {
            this.getData();
        },
        components: {
            vPagination
        },
        methods: {
            formatNumber(value) {
                let val = (value / 1).toFixed(2).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            pilihanstatus: function(e) {
                if (e.target.value == 'Berbayar') {
                    this.showSH = true;
                    this.form.biaya_formulir = "";
                    this.readonlyDisable = false;
                } else {
                    this.showSH = true;
                    this.form.biaya_formulir = "0";
                    this.readonlyDisable = true;
                }
            },
            show_modal() {
                this.form.id = ''
                this.form.kode_formulir = ''
                this.form.nama_formulir = ''
                this.form.unit = ''
                this.form.keterangan = ''
                this.form.biaya_formulir = ''
                this.form.kuota_pendaftar = ''
                this.showHarga = true;
                this.showSH = false
                this.form.statusPemb = ''
                this.readonlyDisableKode = false
                this.$modal.show('my-modal');
            },
            hide_modal() {
                this.$modal.hide('my-modal')
            },
            // Get data Formulir
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/formulir', {
                        params: {
                            page: this.pagination.currentPage,
                            keywords: this.keywords,
                        }
                    })
                    .then((response) => {
                        //assign state formulir with response data
                        this.dataFormulir = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$http.get(this.baseUrl + 'admin/master/unitGroup')
                    .then((response) => {
                        this.dataUnit = response.data.data;
                        this.fetching = false;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            previewFiles(e) {
                const file = e.target.files[0];
                var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

                if (fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {

                    this.preview_image = URL.createObjectURL(file);
                    const preview_area = document.getElementById("file-uploaded-section");
                    const prebview_before = document.getElementById("file-uploaded-section-before");
                    const preview_area_image = document.getElementById("file-uploaded-area");

                    preview_area.classList.remove("d-none");
                    prebview_before.classList.add("d-none");
                    preview_area_image.style.backgroundImage = "url('" + this.preview_image + "')";

                    this.form.cover = file;
                }
            },
            addsubunit() {
                this.$http.get(this.baseUrl + `admin/master/formulir/subunit?id=${this.form.unit_id}`)
                    .then((response) => {
                        this.dataSubUnit = response.data.data;
                        if (response.data.status == false) {
                            this.dataSubUnit = [];
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            // Save data Formulir
            submitForm() {
                if (this.form.kode_formulir.length == 0 || this.form.nama_formulir.length == 0 || this.form.biaya_formulir.length == 0 || this.form.kuota_pendaftar.length == '' || this.form.unit_id.length == '') {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'inputan tidak boleh kosong',
                    })
                } else if (this.form.kode_formulir.length != 3) {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Kode Formulir harus 3 digit',
                    })
                } else {

                    const upload_bar = document.getElementById("upload-bar");
                    const progress_button = document.getElementById("progress-button");
                    const upload_button = document.getElementById("upload-button");

                    upload_button.classList.add("d-none");
                    upload_bar.classList.remove("d-none");
                    progress_button.classList.remove("d-none");

                    if (this.form.cover.name) {
                        this.uploadFile = true;

                        const callback = this.$apiconfig + 'admin/master/formulir/save';
                        let formData = new FormData();

                        formData.append('file[]', this.form.cover);
                        formData.append('file_path', "formulir/");
                        formData.append('callback', callback);

                        this.$http.post(this.$api_upload_engine, formData, {
                                onUploadProgress: function(progressEvent) {
                                    this.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                                }.bind(this)
                            })
                            .then(response => {
                                this.uploadFile = false;
                                const res = response.data;

                                if (res.status && res.result != "") {
                                    this.saveData(res.result[0]['path']);
                                } else {
                                    this.$swal({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Gagal mengunggah file, coba lagi nanti!',
                                    })
                                }
                            });
                    } else {
                        this.saveData(this.form.cover);
                    }
                }

            },
            saveData: function(foto) {
                const upload_bar = document.getElementById("upload-bar");
                const progress_button = document.getElementById("progress-button");
                const upload_button = document.getElementById("upload-button");


                this.$http.post(this.$apiconfig + 'admin/master/formulir/save', {
                        id: this.form.id,
                        kode_formulir: this.form.kode_formulir,
                        nama_formulir: this.form.nama_formulir,
                        unit_id: this.form.unit_id,
                        sub_unit_id: this.form.sub_unit_id,
                        keterangan: this.form.keterangan,
                        biaya_formulir: this.form.biaya_formulir,
                        kuota_pendaftar: this.form.kuota_pendaftar,
                        showHarga: true,
                        showSH: false,
                        statusPemb: this.form.statusPemb,
                        file_attachment: foto,
                    })
                    .then(() => {
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: "Data Formulir berhasil disimpan",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$modal.hide('my-modal')
                        this.getData()
                        this.form.kode_formulir = ''
                        this.form.nama_formulir = ''
                        this.form.keterangan = ''
                        this.form.biaya_formulir = ''
                        this.form.kuota_pendaftar = ''
                        this.form.status = ''

                        upload_bar.classList.add("d-none");
                        progress_button.classList.add("d-none");
                        upload_button.classList.remove("d-none");

                    })
                    .catch(error => {
                        console.log(error.response.data)
                    });

            },
            // Edit data Formulir
            editForm(id) {
                this.$http.get(this.baseUrl + `admin/master/formulir/detail?id=${id}`)
                    .then((response) => {
                        if (response.data.status_pemakaian != 'digunakan') {
                            this.showHarga = true
                            this.readonlyDisableKode = false
                        } else {
                            this.showHarga = false
                            this.readonlyDisableKode = true
                        }
                        //assign state periode with response data
                        this.form.id = response.data.data[0].id;
                        this.form.kode_formulir = response.data.data[0].kode_formulir;
                        this.form.nama_formulir = response.data.data[0].nama_formulir;
                        this.form.unit_id = response.data.data[0].unit_id;
                        this.form.sub_unit_id = response.data.data[0].sub_unit_id;
                        this.form.keterangan = response.data.data[0].keterangan;
                        this.form.kuota_pendaftar = response.data.data[0].kuota_pendaftar;
                        this.form.status = response.data.data[0].status;

                        const preview_area_image = document.getElementById("file-uploaded-area-temp");
                        preview_area_image.style.backgroundImage = 'url("' + response.data.data[0].cover + '")';

                        if (response.data.data[0].biaya_formulir == 0) {
                            this.showSH = true;
                            this.form.biaya_formulir = "0";
                            this.form.statusPemb = "Gratis";
                        } else {
                            this.showSH = true;
                            this.form.biaya_formulir = response.data.data[0].biaya_formulir;
                            this.form.statusPemb = "Berbayar";
                        }
                    }).then(() => {
                        this.$http.get(this.baseUrl + `admin/master/formulir/subunit?id=${this.form.unit_id}`)
                            .then((response) => {
                                this.dataSubUnit = response.data.data;
                                if (response.data.status == false) {
                                    this.dataSubUnit = [];
                                }
                            })
                            .catch((error) => {
                                console.log(error.response.data);
                                this.fetching = false;
                            });
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
                this.$modal.show('my-modal');
            },
            // Delete data Formulir
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Data yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'admin/master/formulir/delete',
                            method: 'post',
                            data: fd
                        }).then((response) => {
                            if (response.data.status_pemakaian != 'digunakan') {
                                this.$swal(
                                    'Deleted!',
                                    'Data deleted successfully.',
                                    'success'
                                )
                            } else {
                                this.$swal(
                                    'Tidak dapat di hapus!',
                                    'Formulir sudah digunakan.',
                                    'info'
                                )
                            }
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>